

import Home from "../components/Home";
//import EngineeredComponents from "../components/EnggComponent";
import Blog from "../components/Blog";
import Contact from "../components/Contact";
import About from "../components/About";

import Level3 from "../components/Level3";
import Level2 from "../components/Level2";
import Level1 from "../components/Level1";
import Leadership from '../components/Leadership';
import { useLocation } from 'react-router-dom'
import BlogDetails from '../components/sections/NewsAndSearch/BlogDetails'
import SearchResults from '../components/SearchResults';
import Rfq from '../components/Rfq';
import PrivacyPolicy from '../components/PrivacyPolicy';

import OurBoard from "../components/OurBoard";
import Level4 from "../components/Level4";
import PageNotFound from "../components/PageNotFound";
import PerformanceMaterialsRFQ from "../components/PerformanceMaterialsRFQ";

export const bioRoutes =
    [
        {
            name: "Home",
            path: "/",
            exact: true,
            component: Home
        },
         {
            key:"automation",
            name: "automation",
            path: "/automation",
            exact: true,
            component: Level2
        },
        {
            key: "NewAutoDevelopment",
            name: "NewAutoDevelopment",
            path: "/NewAutoDevelopment",
            exact: true,
            component: Level2
        },
        {
            name: "Engineered Component Services",
            path: "/EngineeredComponents",
            exact: true,
            component: Level1,
            activeClassName: "active"
        },
        {
            name: "Blog",
            path: "/News&Resources",
            exact: true,
            component: Blog
        },
        {
            name: "Contact",
            path: "/Contact",
            exact: true,
            component: Contact
        },
        {
            name: "About",
            path: "/About",
            exact: true,
            component: About
        },
        {
            key:"AboutValues",
            name: "About",
            path: "/Values",
            exact: true,
            component: About
        },

        {
            key:"Internships",
            name: "Internships",
            path: "/Internships",
            exact: true,
            component: Level2
        },
        {
            key:"Apprenticeships",
            name: "Apprenticeships",
            path: "/Apprenticeships",
            exact: true,
            component: Level2
        },
        {
            key:"Superate",
            name: "Superate",
            path: "/Superate",
            exact: true,
            component: Level2
        },


        {
            key: "LaserProcessing",
            name: "LaserProcessing",
            path: "/LaserProcessing",
            exact: true,
            component: Level4
        },

        {
            key: "Micromachining",
            name: "micromachining",
            path: "/Micromachining",
            exact: true,
            component: Level4
        },
        {
            key: "Micro",
            name: "MicroOvermolding",
            path: "/MicroOvermolding",
            exact: true,
            component: Level4
        },
        {
            key: "MicroMetalsFinishedAssembly",
            name: "MicroMetalsFinishedAssembly",
            path: "/MicroMetalsFinishedAssembly",
            exact: true,
            component: Level4
        },
        {
            key: "MetalFabrication",
            name: "MetalFabrication",
            path: "/MetalFabrication",
            exact: true,
            component: Level4
        },
        {
            key: "MetalFinishing",
            name: "MetalFinishing",
            path: "/MetalFinishing",
            exact: true,
            component: Level4
        },
        {
            key: "MetalInjectionMolding",
            name: "MetalInjectionMolding",
            path: "/MetalInjectionMolding",
            exact: true,
            component: Level4
        },

        {
            key: "ComplexExtrusions",
            name: "ComplexExtrusions",
            path: "/ComplexExtrusions",
            exact: true,
            component: Level2
        },
        {
            key: "CleanroomMolding",
            name: "CleanroomMolding",
            path: "/CleanroomMolding",
            exact: true,
            component: Level2
        },
        {
            name: "PerformanceMaterials",
            path: "/PerformanceMaterials",
            exact: true,
            component: Level2
        },
        {
            name: "Metals",
            path: "/Metals",
            exact: true,
            component: Level2
        },
        {
            name: "AdvancedCatheters&Steerables",
            path: "/AdvancedCatheters&Steerables",
            exact: true,
            component: Level2
        },
        {
            name: "ManufacturingLineTransfers",
            path: "/ManufacturingLineTransfers",
            exact: true,
            component: Level2
        },
        {
            name: "DesignServices",
            path: "/DesignServices",
            exact: true,
            component: Level1
        },
        {
            name: "FinishedDevices",
            path: "/FinishedDevices",
            exact: true,
            component: Level1
        },
        {
            name: "Materials",
            path: "/Materials",
            exact: true,
            component: Level1
        },
        {
            name: "PharmaceuticalPackaging",
            path: "/PharmaceuticalPackaging",
            exact: true,
            component: Level3
        },
        {
            name: "Leadership",
            path: "/Leadership",
            exact: true,
            component: Leadership
        },
        {
            name: "Careers",
            path: "/Careers",
            exact: true,
            component: Level2
        },

        {
            name: "Balloons & BalloonCatheters",
            path: "/Balloons&BalloonCatheters",
            exact: true,
            component: Level2
        },

        {
            name: "SearchResults/:searchValue/*",
            path: `/SearchResults/:searchValue/*`,
            exact: true,
            component: SearchResults
        },
        {
            name: "WhoWeAre",
            path: "/WhoWeAre",
            exact: true,
            component: Level2
        },
        {
            name: "Markets",
            path: "/Markets",
            exact: true,
            component: Level2
        },
        {
            name: "Quality",
            path: "/Quality",
            exact: true,
            component: Level2
        },
        {
            name: "Certifications",
            path: "/Certifications",
            exact: true,
            component: Level2
        },
        {
            name: "Locations",
            path: "/Locations",
            exact: true,
            component: Level2
        },
        {
            name: "ImageGuidedIntervention",
            path: "/ImageGuidedIntervention",
            exact: true,
            component: Level2
        },

        {
            name: "BlogDetails/*",
            path: "/BlogDetails/*",
            exact: true,
            component: BlogDetails
        },

        {
            name: "Rfq",
            path: "/Rfq",
            exact: true,
            component: Rfq
        },
        {
            name: "Privacy-Policy",
            path: "/Privacy-Policy",
            exact: true,
            component: PrivacyPolicy
        },
        {
            name: "ComponentDevelopment",
            path: "/ComponentDevelopment",
            exact: true,
            component: Level2
        },
        {
            name: "NewProductDevelopment",
            path: "/NewProductDevelopment",
            exact: true,
            component: Level2
        },
        {
            name: "TechTransfer",
            path: "/TechTransfer",
            exact: true,
            component: Level2
        },
        {
            name: "TintubeManufacturing",
            path: "/TintubeManufacturing",
            exact: true,
            component: Level4
        },
        {
            name: "MDICoating",
            path: "/MDICoating",
            exact: true,
            component: Level4
        },

        {
            name: "MyTechTransfer",
            path: "/MyTechTransfer",
            exact: true,
            component: Level3
        },

        {
            name: "InterventionalMedicalPlastics",
            path: "/InterventionalMedicalPlastics",
            exact: true,
            component: Level3
        },

        {
            name: "MicroMetalsProcessing",
            path: "/MicroMetalsProcessing",
            exact: true,
            component: Level3
        },

        {
            name: "AdditiveManufacturing",
            path: "/AdditiveManufacturing",
            exact: true,
            component: Level4
        },
        {
            name: "/AdvancedThermoforming",
            path: "/AdvancedThermoforming",
            exact: true,
            component: Level4
        },

        {
            name: "InjectionMolding",
            path: "/InjectionMolding",
            exact: true,
            component: Level4
        },
        {
            name: "LiquidResinCasting",
            path: "/LiquidResinCasting",
            exact: true,
            component: Level4
        },
        {
            key: "MicroPrecisionStamping",
            name: "MicroPrecisionStamping",
            path: "/MicroPrecisionStamping",
            exact: true,
            component: Level4
        },

        {
            name: "OurBoard",
            path: "/OurBoard",
            exact: true,
            component: OurBoard
        },
        {
            key: "Guidewires",
            name: "Guidewires",
            path: "/Guidewires",
            exact: true,
            component: Level4
        },
        {
            key: "MetalInjectionMolding",
            name: "MetalInjectionMolding",
            path: "/metalinjectionmolding",
            exact: true,
            component: Level4
        },
        {
            key: "PerformanceMaterialsRFQ",
            name: "PerformanceMaterialsRFQ",
            path: "/PerformanceMaterialsRFQ",
            exact: true,
            component: PerformanceMaterialsRFQ
        },

   

        {
            name: "Path not found",
            path: "*",
            exact: true,
            component: PageNotFound
        },


    ]








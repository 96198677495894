//home page section reference for content extracing from API response

export const HERO_SECTION_HOME_PAGE_REFERENCE="HomePage-HeroSection";
export const END_TO_END_HOME_PAGE_REFERENCE="HomePage-EndToEndCapabilities";
export const CAPABILITY_SECTION_HEADER_REFERENCE="Field_row_capabilities";
export const CAPABILITY_SECTION_CONTENT_REFERENCE="HomePage_Capabilities";
export const BIO_DIFF_SECTION_HEADER_HOME_PAGE_REFERENCE="Biomerics_difference_heading"
// export const BIO_DIFF_SECTION_CONTENT_HOME_PAGE_REFERENCE="Field_row_biomerics_difference";
export const BIO_DIFF_SECTION_CONTENT_HOME_PAGE_REFERENCE="Biomerics_difference";
export const STRATEGIC_SECTION_CONTENT_HOME_PAGE_REFERENCE="Building_Strategic_Partnerships"
export const NEWS_RESOURCE_SECTION_HEADER_HOME_PAGE_REFERENCE="Field_row_bio_news";
export const NEWS_RESOURCE_SECTION_CONTENT_HOME_PAGE_REFERENCE="HomePage_News_Resources";
export const CREATIVE_SECTION_CONTENT_HOME_PAGE_REFERENCE ="Field_row_creative_solutions";
//export const CREATIVE_SECTION_CONTENT_ENGG_COMPONENT_REFERENCE ="ECservices_CreativeSolutions";

//Common Creative Solution for all templates
export const COMMON_CREATIVE_SECTION_CONTENT_REFERENCE = "Creative_Solutions_For_Tough_Challenges";

//Engg Component page section reference for content extracing from API response
export const ENNG_COMPONENT_HOME_PAGE_REFERENCE="ECservies_Page-HeroSection"; //
export const PROACTIVELY_ENGG_CONTENT_REFERENCE="ECservies_Page-Proactively_engineered";
export const VERTICAL_INTERGRATION_ENGG_CONTENT_REFERENCE="ECservies_Page-VerticalIntegration";
export const WELL_VERSED_CONTENT_REFERENCE="ECservies_Page-Well_versed_in_both_ploymers";
export const SPEED_INNOVATION_CONTENT_REFERENCE="ECservies_Page-CentersOfExcellence";

//Balloon Catheters  page section reference for content extracing from API response
export const BALLOON_CATHETERS_HEADING_PAGE_REFERENCE="balloon_catheters_hero_section"; //
export const BALLOON_CATHETERS_WE_KNOW_CONTENT_REFERENCE="balloon_catheters_WeKnowBalloons";
// export const BALLOON_CATHETERS_VERTICAL_INTERGRATION_REFERENCE="balloon_catheters_vertically_integrated";
// export const BALLOON_CATHETERS_ENGINEERED_CONTENT_REFERENCE="balloon_catheters_engineered_advantage";
// export const BALLOON_CATHETERS_CONTENT_INHOUSE_TESTING_REFERENCE="balloon_catheters_inhouse_testing_lab";
// export const BALLOON_CATHETERS_VIRTUAL_TOUR_CONTENT_REFERENCE="balloon_catheters_virtual_tour";
// export const BALLOON_CATHETERS_WIDE_RANGE_CONTENT_REFERENCE="balloon_catheters_wide_range_applications";
// export const BALLOON_CATHETERS_NEED_RANGE_CONTENT_REFERENCE="balloon_catheters_need_balloon_fast";
export const BALLOON_CATHETERS_TABLE_CONTENT_REFERENCE="balloon_capabilities_table";
export const BALLOON_CATHETERS_HAVE_QUESTION_CONTENT_REFERENCE="balloon_catheters_have_question";


//Contact Component page section reference for content extracing from API response
export const CONTACT_HERADR_REFERENCE="contact_us_hero_section";
export const CONTACT_INFUSION_REFERENCE="contact_us_let_get_started";
export const CONTACT_US_CONTENT_REFERENCE="Contact Us - hq_contact_details";
export const CONTACT_US_THANKS_CONTENT_REFERENCE="Contact Us - thanks_for_contacting";

//Complex 
export const COMPLEX_EXTRUSION_HEADING_PAGE_REFERENCE="Complex Extrusions - Hero_Section"; 
export const COMPLEX_EXTRUSION_CUSTOM_CONTENT_REFERENCE="Complex Extrusions - Custom extrusions";
export const COMPLEX_EXTRUSION_VERTICAL_INTERGRATION_REFERENCE="Complex Extrusions - Vertically_Integrated";
export const COMPLEX_EXTRUSION_VIRTUAL_TOUR_CONTENT_REFERENCE="Complex Extrusions - Virtual_tour";
export const COMPLEX_EXTRUSION_ADVANCED_CONTENT_REFERENCE="Complex Extrusions - Advanced extrusion";
export const COMPLEX_EXTRUSION_BROAD_SPECTRUM_REFERENCE="Complex Extrusions - Broad spectrum";
export const COMPLEX_EXTRUSION_TAPERED_CONTENT_REFERENCE="Complex Extrusions - Tapered extrusions";
export const COMPLEX_EXTRUSION_COEXTRUSION_CONTENT_REFERENCE="Complex Extrusions - Coextrusion";
export const COMPLEX_EXTRUSION_JACKETING_CONTENT_REFERENCE="Complex Extrusions - Jacketing extends";
export const COMPLEX_EXTRUSION_BALLOON_CONTENT_REFERENCE="Complex Extrusions - Balloon catheter";
export const COMPLEX_EXTRUSION_VERSATILE_REFERENCE="Complex Extrusions - Versatile Prolix";
export const COMPLEX_EXTRUSION_CUSTOM_FLUID_CONTENT_REFERENCE="Complex Extrusions - Custom fluid";
export const COMPLEX_EXTRUSION_HAVE_QUESTION_CONTENT_REFERENCE="Complex Extrusions - Have an question";
export const COMPLEX_EXTRUSION_TABLE_CONTENT_REFERENCE="complex_extrusions_extrusion_capabilities";


//Cleanroom Molding 
export const CLEANROOM_MOLDING_HEADING_PAGE_REFERENCE="cleanroom_molding_hero_section"; 
export const CLEANROOM_MOLDING_COMPLETE_CONTENT_REFERENCE="cleanroom_molding_complete_molding";
export const CLEANROOM_MOLDING_VERTICAL_INTERGRATION_REFERENCE="cleanroom_molding_vertically_integrated";
export const CLEANROOM_MOLDING_CENTER_CONTENT_REFERENCE="cleanroom_molding_molding_center";
export const CLEANROOM_MOLDING_WORLD_CLASS_CONTENT_REFERENCE="cleanroom_molding_world_class_facilities";
export const CLEANROOM_MOLDING_VIRTUAL_TOUR_CONTENT_REFERENCE="cleanroom_molding_virtual_tour";
export const CLEANROOM_MOLDING_EXPERIENCE_CROSS_CONTENT_REFERENCE="cleanroom_molding_experienced_cross_functional";
export const CLEANROOM_MOLDING_BROAD_MOLDING_CONTENT_REFERENCE="cleanroom_molding_broad_molding_capabilities";
export const CLEANROOM_MOLDING_MOLDED_CONTENT_REFERENCE="cleanroom_molding_molded_components";
export const CLEANROOM_MOLDING_COMPLETE_TOOLING_CONTENT_REFERENCE="cleanroom_molding_complete_tooling ";
export const CLEANROOM_MOLDING_BALLOON_CONTENT_REFERENCE="cleanroom_molding_technology_transfer";
export const CLEANROOM_MOLDING_SECONDRY_OPERATION_REFERENCE="cleanroom_molding_secondary_operations";
export const CLEANROOM_MOLDING_HAVE_QUESTION_CONTENT_REFERENCE="cleanroom_molding_have_a_question";


//Laser
export const LASER_HEADING_PAGE_REFERENCE="laser_processing_hero_section"; 
export const LASER_UNIVALED_PAGE_REFERENCE="laser_processing_unrivaled_laser";
export const LASER_VERTICAL_INTEGRATED_PAGE_REFERENCE="laser_processing_vertically_integrated";
export const LASER_VERTICAL_FAST_PAGE_REFERENCE="laser_processing_fast_facts";
export const LASER_VERTICAL_VIRTUAL_TOUR_PAGE_REFERENCE="laser_processing_take_virtual_tour";
export const LASER_VERTICAL_CALIFORNIA_FACILITY_PAGE_REFERENCE="laser_processing_california_facility";
export const LASER_VERTICAL_ENGINEERED_PAGE_REFERENCE="laser_processing_engineering_expertise";
export const LASER_VERTICAL_PULSED_PAGE_REFERENCE="laser_processing_pulsed";
export const LASER_VERTICAL_MODULAR_LASER_PAGE_REFERENCE="laser_processing_modular_laser";
export const LASER_VERTICAL_WELD_SECTIONS_PAGE_REFERENCE="laser_processing_ weld_stations";
export const LASER_VERTICAL_INTERVENTIONAL_PAGE_REFERENCE="laser_processing_interventional_device";
export const LASER_VERTICAL_TABLE_PAGE_REFERENCE="laser_processing_table";

//MicroMatching
export const MICRO_HEADING_PAGE_REFERENCE="micromachining_hero_section"; 
export const MICRO_AGILE_PAGE_REFERENCE="agile_micromachining_experts";
export const MICRO_CENTER_OF_EXECELLENCE_PAGE_REFERENCE="micromachining_center_of_excellence";
export const MICRO_CENTER_OF_FAST_PAGE_REFERENCE="micromachining_fast_facts"
export const MICRO_CENTER_OF_VIRTUAL_TOUR_PAGE_REFERENCE="micromachining_virtual_tour";
export const MICRO_CENTER_OF_CALIFORNIA_PAGE_REFERENCE="micromachining_california_facility";
export const MICRO_CENTER_OF_LEVERAGING_LASER_PAGE_REFERENCE="micromachining_leveraging_laser_processing_machining"
export const MICRO_CENTER_OF_MACHINING_CENTER_PAGE_REFERENCE="micromachining_machining_center";
export const MICRO_CENTER_OF_SCREW_MACHINING_PAGE_REFERENCE="micromachining_screw_machining";
export const MICRO_CENTER_OF_SPECTRUM_PAGE_REFERENCE="micromachining_spectrum_of_applications";
export const MICRO_CENTER_OF_METAL_MICROCOMPONENT_PAGE_REFERENCE="micromachining_metal_microcomponent";

//Common Repitative section for every Level2 section
export const SELECTION_TYPE_PAGE_REFERENCE="image_content_loop_section";

//medical
export const MEDICAL_POLYMER_BROADCAST_REANG = "medical_polymer_broadest_range";
export const MEDICAL_POLYMER_HERO_SECTION = "medical_polymer_hero_section";
export const MEDICAL_POLYMER_WONDERING = "medical_polymer_wondering";
export const GET_IN_TOUCH_WITH_AN_EXPERT = "get_in_touch_with_an_expert";



//metal
export const METALS_HELPING_CHOOES = "metals_helping_choose";
export const METALS_HERO_SECTION = "metals_hero_section";
export const METALS_RELATED_PRODUCTS = "metals_related_products";

//metal
export const ADVANCED_CATHETERS_HIGHLY_SPECI = "advanced_catheters_highly_specialized_catheter";
export const ADVANCED_CATHETERS_HERO_SECTION = "advanced_catheters_hero_section";
export const ADVANCED_CATHETERS_RELATED_PRODUCTS = "advanced_catheters_related_products";
export const ADVANCED_CATHETERS_TABLE_CONTENT = "advanced_catheters_table";

//Manufacturing Line Transfers
export const MANUFACTURING_HERO_SECTION = "manufacturing_line_transfers_hero_section";
export const MANUFACTURING_TRUST_PARTNER = "manufacturing_line_transfers_your_trusted_partner";
export const MANUFACTURING_RELATED_HAVE_QUESTION = "manufacturing_line_transfers_have_a_question";

//--------Level 1 ------------------//
//DEsgin Service
export const DESIGN_SERVICES_HERO_SECTION = "design_services_hero_section";
export const DESIGN_SERVICES_ACCELERATING="design_services_accelerating_design ";
export const DESIGN_SERVICES_FLEXIBLITY="design_services_known_for_flexibility";
export const DESIGN_SERVICES_VERTICAL_DEPTH="design_services_vertical_depth";
export const DESIGN_SERVICES_FOCUS="design_services_focus_on_dfm";
export const DESIGN_SERVICES_WORLD_CLASS="design_services_world_class_design";
export const DESIGN_SERVICES_PROJECT_MANAGEMENT="design_services_project_management";

//Materials

export const MATERIAL_AND_COMPOUNDING_FOCUS ="materials_&amp;_compunding_hero_section";
export const MATERIAL_AND_COMPOUNDING_WORLD_CLASS ="materials_&amp;_compunding_experts_in_both";
export const MATERIAL_AND_COMPOUNDING_PROJECT_MANAGEMENT ="materials_&amp;_compounding_metal_machining";

//finished Device
export const FINISHED_DEVICES_HERO ="finished_devices_hero_section";
export const FINISHED_DEVICES_END_TO_END ="finished_devices_end_to_end_capabilities";
export const FINISHED_DEVICES_STRATEGICALLY_LOCATED ="finished_devices_strategically_located_facilities";


//Common Repitative section for every Level2 section

export const SELECTION_TYPE_PAGE_REFERENCE_LEVEL_1="image_content_loop_section";

//About Us
export const ABOUT_US_HERO_SECTION = "about_us_hero_section";
export const ABOUT_US_ENGINEERING_COMPANY="about_us_engineering_company";
export const ABOUT_US_FOCUSED_OUR_VISION="about_us_focused_on_our_vision";
export const ABOUT_US_LIVING_OUR_VALUES="about_us_living_our_values";
export const ABOUT_US_GROWTH_EXPANSION="about_us_growth_expansion";
export const ABOUT_US_MEDTICAL_DEVICE="about_us_medical_device";
 export const ABOUT_US_GET_TO_KNOW="about_us_get_to_know";

 //leader ship level 2
 export const LEADERSHIP_HERO_SECTION = "leadership_hero_section";
 export const LEADERSHIP_MEET_EXECUTIVE_SECTION = "leadership_meet_our_executives";
 

 //careers level 2
 export const CAREERS_HERO_SECTION = "careers_hero_section";
 export const CAREERS_JOIN_TEAM_SECTION = "careers_join_our_team";
 export const CAREERS_ASK_QUESTION= "ask_a_career_question";

 //MENUFACTURING level 2
 export const MENUFACTURING_LINE_HERO_SECTION = "manufacturing_line_transfers_hero_section";
 export const MENUFACTURING__LINE_TRANSFER_TRUST_SECTION = "manufacturing_line_transfers_your_trusted_partner";
 export const MENUFACTURING_CAREERS_ASK_QUESTION= "manufacturing_line_transfers_have_a_question";
 
 //MENUFACTURING level 2
 export const WHO_WE_ARE_HERO_SECTION = "who_we_are_hero_section";
 export const WHO_WE_ARE_LINE_TRANSFER_TRUST_SECTION = "who_we_are_bringing_complex";
 export const WHO_WE_ARE_ASK_QUESTION= "who_we_are";

 //MARKETS LEVEL 2
export const MARKETS_HERO_SECTION = "markets_hero_section";
 export const MARKETS_INTERVENTIONAL_SECTION = "markets_interventional_device";
export const MARKETS_ASK_QUESTION= "markets_question";

//COMPONENT LEVEL 2
export const COMPONENT_HERO_SECTION = "component_development_header";
 export const COMPONENT_SECTION = "robust_proven_development_process";
export const COMPONENT_ASK_QUESTION= "medical_device_component";

//NEW_PRODUCT LEVEL 2
export const NEW_PRODUCT_HERO_SECTION = "new_product_development";
export const NEW_PRODUCT_SECTION = "collaborating_from_design";
export const NEW_PRODUCT_ASK_QUESTION_SECTION= "manufacture_your_medical_device";

//TECH_TRANSFER LEVEL 2
export const TECH_TRANSFER_HERO_SECTION = "tech_transfer_header";
export const TECH_TRANSFER_SECTION = "bringing_design_to_technology_transfers";
export const TECH_TRANSFER_ASK_QUESTION_SECTION = "transfer_or_scale_your_tech";



//New Auto Dev LEVEL 2
export const NEW_AUTO_DEV_HERO_SECTION = "new_auto_development_header";
 export const NEW_AUTO_DEV_SUB_SECTION = "sub_line_builds";

//QUALITY
export const QUALITY_HERO_SECTION = "quality_hero_section";
export const QUALITY_FULLY_COMPLAINT_SECTION = "quality_fully_compliant";
//certifications
export const CERTIFICATIONS_HERO_SECTION = "certifications_hero_section";
export const CERTIFICATIONS_FULLY_COMPLAINT_SECTION = "certifications_iso_certificates";

export const LOCATIONS_HERO_SECTION = "locations_hero_section";
export const LOCATIONS_FULLY_COMPLAINT_SECTION = "locations_strategic_locations";

 //IMAGEGUIDEDINTERVENSION
 export const IMAGEGUIDEDINTERVENSION_HERO_SECTION = "image_guided_intervention_hero_section";
 export const IMAGEGUIDEDINTERVENSION_SECTION = "image_guided_intervention";
 export const IMAGEGUIDEDINTERVENSION_QUESTION_SECTION ="image_guided_reference_question";
 export const IMAGEGUIDEDINTERVENSION_TABLE_SECTION ="IGI_table";

 //Blogs
 export const BLOG_CARD_SECTION = "blog_card";
 export const PINNED_BLOG_CARD_SECTION = "pinned_blog";
 //Blog Header
export const BLOG_HERO_SECTION = "news_resources_header";
export const BLOG_GET_TO_KNOW_SECTION ="get_to_know_us";
export const BLOG_DROUPDOWN_TYPE ="type_drop_down";
export const BLOG_DROUPDOWN_TOPIC ="topic_drop_down";
 //BLOG DETAIL
//export const BLOG_GET_TO_KNOW_US = "get_to_know_us";
//export const BLOG_DETAIL_HEADER = "news_resources_header";

//RFQ
export const RFQ_HERO_SECTION ="rfq_header";
export const RFQ_QUOTE_SECTION ="rfq_form";
export const RFQ_QUOTE_SECTION_FOOTER="rfq_footer";

//privacy policy
export const PRIVACY_POLICY_DETAILS ="privacy_policy_detail";


////Latest dynamic api L3 pages 
export const L3_DYNAMIC_HEADER_SECTION ="l3_dynamic_header_section";
export const L3_DYNAMIC_OVERVIEW_SECTION ="l3_dynamic_overview_section";
export const L3_DYNAMIC_CONTENT_LOOP_SECTION="image_content_loop_section";
export const L3_DYNAMIC_GET_IN_TOUCH_SECTION='l3_dynamic_get_in_touch_section';

//API for L4 pages
export const L4_HEADER_SECTION = 'L4_HEADER_SECTION';
export const L4_CONTENT_LOOP_SECTION = 'L4_CONTENT_LOOP_SECTION';
export const L4_GET_IN_TOUCH_SECTION = 'L4_GET_IN_TOUCH_SECTION';

 //OUR BOARD PAGE

  export const OURBOARD_HERO_SECTION = "ourboard_hero_section";
  export const OURBOARD_MEET_EXECUTIVE_SECTION = "ourboard_meet_our_executives";

  
  //Performance Materials RFQ page for lead generation
export const LEAD_GEN_HEADER_REFERENCE="performance_materials_rfq_hero_section";
export const LEAD_GEN_INFUSION_REFERENCE="performance_materials_rfq_lets_connect";
export const LEAD_GEN_CONTENT_REFERENCE="performance_materials_rfq_hq_contact_details";


//Automation page

export const AUTOMATION_HERO_SECTION="automation_header";
export const AUTOMATION_LINE_BUILD_SECTION="custom_automation_line_builds";
export const AUTOMATION_GET_IN_TOUCH="automation_get_in_touch";